import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import AddCarKms from "../car/add-car-kms";
import {useIntl, Link, navigate} from "gatsby-plugin-intl"

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const UpdateCar = ({location}) => {
  const intl = useIntl();

  const pageContent = (
    <div className="col-lg-8">
      <div className="mobile-container">
        <Link to="/mycars/my-cars/" state={{id: location.state ? location.state.id : ""}} className="black mb-4 back-link"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({id: "generic.nav_buttons.back_button"})}</strong></Link>
      </div>
      <AddCarKms navigateTo='/mycars/last-service/'/>
    </div>
  );

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.update-car" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
    )
};

export default UpdateCar
